import { Injectable } from "@angular/core";
import * as moment from 'moment';

export class ValidatorService{
     ENERGYTYPE_GAS = 'GAS';
     ENERGYTYPE_ELECTRICITY = 'ELEC';
     PROPERTYTYPE_HOME = 'home';
     PROPERTYTYPE_BUSINESS = 'bus';
     DATE_VALIDATION_FAILED = 'Please check the date';

     USGTYPE_PEAK = 'peak';
     USGTYPE_OFFPEAK = 'off-peak';
     USGTYPE_CONTROLLEDLOAD = 'controlled';
      USGTYPE_CONTROLLEDLOAD2 = 'controlled 2';
      USGTYPE_SHOULDER = 'shoulder';
      USGTYPE_SERVICETOPROP = 'service to property';
      USGTYPE_CONTROLLEDLOADSERVICETOPROP = 'controlled service to property';
      USGTYPE_CONTROLLEDLOAD2SERVICETOPROP = 'controlled 2 service to property';
      USGTYPE_SOLARTARIFF = 'fit';
      USGTYPE_SOLARTARIFF2 = 'fit 2';
      USGTYPE_GREEN = 'green';
      USGTYPE_DEMAND = 'demand';
      USGTYPE_DISCOUNT = 'discount';
      USGTYPE_ADJUSTMENT = 'adjustment';

      PERIOD_ERROR = 'Please enter the period!';
      TOTAL_ERROR = 'Please enter the total!';
      GST_ERROR = 'Please enter your GST amount!';

      USAGELIST_ERROR = 'Please enter usage details!';
      USAGEAMT_ERROR = 'Please enter the usage amount!';
      USAGETYPE_ERROR = 'Please enter a usage type';

      DATE_ERROR = 'Please enter a proper date!';
      SUPPLYCHARGE_ERROR = 'Please check the service to property rate!';
     DATE_ERROR_FOR_SVCTOPROP = 'Please enter a proper date for Service To Property!';
     USAGEAMTFORELEC = 'Please check the usage type,usage and rate.' + ' If the usage is correct, the rate looks too ';
     USAGEAMTFORGAS = 'Please check the usage type, MJ usage and $ rate.' + ' If the usage is correct, the rate looks too ';

     COSTDIFF_ERROR = 'Cost difference should be less than 2%.';
     ADJUSTMENTRATE_ERROR = 'Check the adjustment rate.';

      ISBETWEEN = 'is between ';
      CENTS = 'cents';
      DOLLARS = '$';
      PERKWH = ' per kWh.';
      PERMJ = ' per MJ.';
      PERDAY = ' per day.';
      AND = 'and';
      LOW = 'low.';
      HIGH = 'high.';

    validateDate(usage) {
      var startdate = moment(usage.startdate);
      var enddate = moment(usage.enddate);
      var today = moment(new Date());
      return (
        enddate.diff(startdate, 'days') >= 0 && startdate <= today && enddate <= today
      );
    };

    validateCostDiff(usage) {
      var isValid = true;
      var result = 0;
      usage.usage.forEach(function(u) {
        result += (u.rate || 0) * (u.usageamount || 0);
      });
      var costdiff = (
        ((usage.dollaramount - result) * 100) / usage.dollaramount
      ).toFixed(2);
      if (Number(costdiff) > 2) {
        isValid = false;
      }
      return isValid;
    };

    validateHomeElectricityUsageRange(usageitem) {
      let error = '';
      const rate = usageitem.rate * 100;
      const a = this.USAGEAMTFORELEC;
      const low = this.LOW;
      const high = this.HIGH;

      //console.log('validateHomeElectricityUsageRange usageitem: ' + JSON.stringify(usageitem));

      var validUsageTypes = [
        this.USGTYPE_PEAK, 
        this.USGTYPE_OFFPEAK, 
        this.USGTYPE_CONTROLLEDLOAD, 
        this.USGTYPE_CONTROLLEDLOADSERVICETOPROP, 
        this.USGTYPE_CONTROLLEDLOAD2SERVICETOPROP, 
        this.USGTYPE_SERVICETOPROP, 
        this.USGTYPE_SHOULDER, 
        this.USGTYPE_SOLARTARIFF, 
        this.USGTYPE_CONTROLLEDLOAD2, 
        this.USGTYPE_SOLARTARIFF2, 
        this.USGTYPE_DISCOUNT, 
        this.USGTYPE_DEMAND, 
        this.USGTYPE_ADJUSTMENT
      ];

      if (validUsageTypes.indexOf(usageitem.usagetype) === -1) {
        error = this.USAGETYPE_ERROR;
      } else {
        switch (usageitem.usagetype) {
          case this.USGTYPE_PEAK:
            error = rate < 14 ? `${a} ${low}` : rate > 95 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_OFFPEAK:
            error = rate < 10 ? `${a} ${low}` : rate > 52 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_CONTROLLEDLOAD:
            error = rate < 8 ? `${a} ${low}` : rate > 46 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_SHOULDER:
            error = rate < 15 ? `${a} ${low}` : rate > 60 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_SOLARTARIFF:
            error = rate < 1 ? `${a} ${low}` : rate > 65 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_CONTROLLEDLOADSERVICETOPROP:
          case this.USGTYPE_CONTROLLEDLOAD2SERVICETOPROP:
            if (rate < 10 || rate > 200) {
              const a = this.SUPPLYCHARGE_ERROR;
              error = `${a}`;
              break;
            }

            break;
          case this.USGTYPE_SERVICETOPROP:
            const startdate = moment(usageitem.startdate);
            const enddate = moment(usageitem.enddate);
            const days = enddate.diff(startdate, 'days');
            if (days < 0) {
              error = this.DATE_ERROR_FOR_SVCTOPROP;
              break;
            } else {
              if (rate < 20 || rate > 700) {
                const a = this.SUPPLYCHARGE_ERROR;
                error = `${a}`;
                break;
              }
            }
        }
      }
      return error;
    };

    validateHomeGasUsageRange(usageitem) {
      let error = '';
      const rate = usageitem.rate * 100;
      const a = this.USAGEAMTFORGAS;
      const low = this.LOW;
      const high = this.HIGH;

      var validUsageTypes = [
        this.USGTYPE_PEAK, 
        this.USGTYPE_SERVICETOPROP, 
        this.USGTYPE_SHOULDER, 
        this.USGTYPE_DISCOUNT, 
        this.USGTYPE_ADJUSTMENT
      ];

      if (validUsageTypes.indexOf(usageitem.usagetype) === -1) {
        error = this.USAGETYPE_ERROR;
      } else {
        switch (usageitem.usagetype) {
          case this.USGTYPE_PEAK:
            error = rate < 1 ? `${a} ${low}` : rate > 4 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_OFFPEAK:
            error = rate < 1 ? `${a} ${low}` : rate > 4 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_CONTROLLEDLOAD:
            error = rate < 1 ? `${a} ${low}` : rate > 4 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_SHOULDER:
            error = rate < 1 ? `${a} ${low}` : rate > 4 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_SOLARTARIFF:
            error = rate < 1 ? `${a} ${low}` : rate > 4 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_SERVICETOPROP:
            const startdate = moment(usageitem.startdate);
            const enddate = moment(usageitem.enddate);
            const days = enddate.diff(startdate, 'days');
            if (days < 0) {
              error = this.DATE_ERROR_FOR_SVCTOPROP;
              break;
            } else {
              if (rate < 20 || rate > 400) {
                const a = this.SUPPLYCHARGE_ERROR;
                error = `${a}`;
                break;
              }
            }
        }
      }
      return error;
    };

    validateBusinessElectricityUsageRange(usageitem) {
      let error = '';
      const rate = usageitem.rate * 100;
      const a = this.USAGEAMTFORELEC;
      const low = this.LOW;
      const high = this.HIGH;

      var validUsageTypes = [
        this.USGTYPE_PEAK, 
        this.USGTYPE_OFFPEAK, 
        this.USGTYPE_CONTROLLEDLOAD, 
        this.USGTYPE_CONTROLLEDLOADSERVICETOPROP, 
        this.USGTYPE_CONTROLLEDLOAD2SERVICETOPROP, 
        this.USGTYPE_SERVICETOPROP, 
        this.USGTYPE_SHOULDER, 
        this.USGTYPE_SOLARTARIFF, 
        this.USGTYPE_CONTROLLEDLOAD2, 
        this.USGTYPE_SOLARTARIFF2, 
        this.USGTYPE_DISCOUNT, 
        this.USGTYPE_DEMAND, 
        this.USGTYPE_ADJUSTMENT
        
      ];

      if (validUsageTypes.indexOf(usageitem.usagetype) === -1) {
        error = this.USAGETYPE_ERROR;
      } else {
        switch (usageitem.usagetype) {
          case this.USGTYPE_PEAK:
            error = rate < 14 ? `${a} ${low}` : rate > 80 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_OFFPEAK:
            error = rate < 9 ? `${a} ${low}` : rate > 50 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_CONTROLLEDLOAD:
            error = rate < 9 ? `${a} ${low}` : rate > 46 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_SHOULDER:
            error = rate < 15 ? `${a} ${low}` : rate > 62 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_SOLARTARIFF:
            error = rate < 1 ? `${a} ${low}` : rate > 60 ? `${a} ${high}` : '';
            break;
          case this.USGTYPE_CONTROLLEDLOADSERVICETOPROP:
          case this.USGTYPE_CONTROLLEDLOAD2SERVICETOPROP:
            if (rate < 10 || rate > 200) {
              const a = this.SUPPLYCHARGE_ERROR;
              error = `${a}`;
              break;
            }

            break;
          case this.USGTYPE_SERVICETOPROP:
            const startdate = moment(usageitem.startdate);
            const enddate = moment(usageitem.enddate);
            const days = enddate.diff(startdate, 'days');
            if (days < 0) {
              error = this.DATE_ERROR_FOR_SVCTOPROP;
            } else {
              if (rate < 60 || rate > 700) {
                const a = this.SUPPLYCHARGE_ERROR;
                error = `${a}`;
              }
            }
            break;
          case this.USGTYPE_ADJUSTMENT:
            if (usageitem.rate < 0 || usageitem.rate > 100) {
              error = this.ADJUSTMENTRATE_ERROR;
            }
            break;
        }
      }

      return error;
    };

    validateBusinessGasUsageRange(usageitem) {
      return this.validateHomeGasUsageRange(usageitem);
    };

    validateElecUsageRange(usageitem, account) {
      if (account.plantype === this.PROPERTYTYPE_HOME) {
        return this.validateHomeElectricityUsageRange(usageitem);
      } else if (account.plantype === this.PROPERTYTYPE_BUSINESS) {
        return this.validateBusinessElectricityUsageRange(usageitem);
      }
    };

    validateGasUsageRange(usageitem, account) {
      if (account.plantype === this.PROPERTYTYPE_HOME) {
        return this.validateHomeGasUsageRange(usageitem);
      } else if (account.plantype === this.PROPERTYTYPE_BUSINESS) {
        return this.validateBusinessGasUsageRange(usageitem);
      }
    };

    validateUsageRange(usageitem, account) {
      var energytype = account.energytype;
      var isValid = this.validateDate(usageitem);
      if (!isValid) {
        return this.DATE_VALIDATION_FAILED;
      }
      if (energytype === this.ENERGYTYPE_ELECTRICITY) {
        return this.validateElecUsageRange(usageitem, account);
      } else if (energytype === this.ENERGYTYPE_GAS) {
        return this.validateGasUsageRange(usageitem, account);
      }
    };

    validateAccount(accountinfo){
      let msg = '';

      if (accountinfo){
        let dups = [];
        let ignore = ['billingcycle'];
       for(let key in accountinfo){
        let data = accountinfo[key];
        if (data.value === '' && ignore.indexOf(data.key) == -1){
          dups.push(data.key);
          msg = 'Value is empty for : ' + data.key;
        }
        if (dups.indexOf(data.key) > -1){
          if (data.value){
            msg = '';
          }
        }
        if (data.key === 'nmi' || data.key === 'mirn'){
          if (data.value.length < 10){
            msg = 'Please check nmi/mire : ' + data.value;
          }
        }
       }
      }
      return msg;
    }

    validate(usage, account) {
      //conole.log('Validator validate usage: ', usage,' account: ',account);

      var message = '';
      if (!this.validateDate(usage)) {
        message = this.DATE_VALIDATION_FAILED;
      } else if (!this.validateCostDiff(usage)) {
        message = this.COSTDIFF_ERROR;
      }  else {
        message = this.validateAccount(usage.accountinfo);
      }
      
      /* else {
        var usagearr = usage.usage;
        for (var key in usagearr) {
          var usg = usagearr[key];
          message = this.validateUsageRange(usg, account) || '';
          if (message.length) {
            break;
          }
        }
      } */
      return message;
    };

   
  }

