import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MainComponent} from './main.component';


@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', loadChildren: '../app/admin/admin.module#AdminModule' },
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
