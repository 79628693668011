import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from 'src/app/app.constants';

@Injectable({
  // we declare that this service should be created
  // by the root application injector.
  providedIn: 'root',
})
export class ParserService {

  constructor(private http: HttpClient) { }


  sendSlackMsg(data) {
    const apiUrl = AppConstants.URL + 'slack/sendSlackMsg';
    return this.http.post(apiUrl, data);
  }
  
  uploadFile(data, type) {
    const apiUrl = AppConstants.URL + 'file/upload?type=' + type;
    return this.http.post(apiUrl, data);
  }

  parsePdf(filepath) {
    const apiUrl = AppConstants.URL + 'pdfparser/parseBill';
    return this.http.post(apiUrl, {'filepath': filepath});
  }

  runtest(data) {
    const apiUrl = AppConstants.URL + 'test/runtest';
    return this.http.post(apiUrl,data);
  }

}
