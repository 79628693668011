import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MainComponent } from './main.component';
import { SlackService } from 'src/app/common/slack.service';
import { AdminService } from 'src/app/admin/admin-service';
import { ParserService } from 'src/app/admin/parser.service';
import { ValidatorService } from './admin/components/billparser/validation.services';


@NgModule({
  declarations: [
    AppComponent, MainComponent
  ],
  imports: [
    BrowserModule, AppRoutingModule, HttpClientModule, BrowserAnimationsModule
  ],
  providers: [SlackService, AdminService, ParserService, ValidatorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
