export class AppConstants {
  public static get ELECTRICITY(): string {return 'Electricity'; }
  public static get GAS(): string {return 'Gas'; }
  public static get URL(): string {return 'api/'; }
  public static get CONFIGPROD(): string {return 'Prod'; }
  public static get VERSION(): string {return '1.0'; }
  public static get PEAK(): string {return 'Peak'; }
  public static get TANGO(): string {return 'Tango Energy'; }
  public static get GLOBIRD(): string {return 'Globird Energy'; }
  public static get DODO(): string {return 'Dodo'; }
  public static get ALINTA(): string {return 'Alinta'; }
  public static get ELYSIAN(): string {return 'Elysian Energy'; }
  public static get AGL(): string {return 'Agl'; }
  public static get ORIGIN(): string {return 'Origin'; }
  public static get LUMO(): string {return 'Lum'; }
  public static get SIMPLYENERGY(): string {return 'Simply Energy'; }
  public static get MOMENTUM(): string {return 'Momentum'; }
  public static get ENERGYAUSTRALIA(): string {return 'Energy Australia' ; }
  public static get AMAYSIM(): string {return 'Amaysim' ; }
  public static get POWERSHOP(): string {return 'Powershop' ; }
  public static get ENERGYLOCALS(): string {return 'EnergyLocals' ; }
  public static get REDENERGY(): string {return 'Red Energy' ; }
  public static get QENERGY(): string {return 'Q Energy' ; }
  public static get REAMPED(): string {return 'ReAmped'; }
  public static get OVO(): string {return 'Ovo'; }
  public static get ELECINABOX(): string {return 'Electricityinabox'; }
  public static get AMBER(): string {return 'Amber Electric'; }
  public static get BLUENRG(): string {return 'BLUENRG'; }
  public static get KOGAN(): string {return 'kogan'; }
  public static get SUMO(): string {return 'Sumo Power'; }
  public static get NEXTBUSINESSENERGY(): string {return 'Next Business Energy'; }
  public static get CLICKENERGY(): string {return 'Click Energy'; }
  public static get FIRSTENERGY(): string {return '1st Energy'; }
  public static get MOJO(): string {return 'Mojo Power'; }
  public static get GLOWPOWER(): string {return 'Glow Power'; }
  public static get POWERDIRECT(): string {return 'Powerdirect'; }
  public static get ACTEWAGL():string {return 'ActewAGL'; }
  public static get NECTR():string {return 'Nectr'; }
  public static get DISCOVERENERGY():string {return 'Discover Energy'; }
}


