import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from 'src/app/app.constants';

@Injectable({
  // we declare that this service should be created
  // by the root application injector.
  providedIn: 'root',
})
export class SlackService {

  constructor(private http: HttpClient) { }


  sendSlackMsg(data) {
    const apiUrl = AppConstants.URL + 'slack/sendSlackMsg';
    return this.http.post(apiUrl, data);
  }

}
