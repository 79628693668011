import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from 'src/app/app.constants';

@Injectable({ providedIn: 'root' })
export class AdminService {
  
  constructor(private http: HttpClient) { }

  public getReadme(data) {
    const apiUrl = AppConstants.URL + 'file/getReadme';
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

}
